import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userName: localStorage.getItem("userName"),
    nickName: localStorage.getItem("nickName"),
    imageUrl: localStorage.getItem("imageUrl"),
    czNum: localStorage.getItem("czNum") || 0,
    djNum: localStorage.getItem("djNum") || 0,
  },
  getters: {},
  mutations: {
    userNameChange(state, payload) {
      localStorage.setItem("userName", payload);
      state.userName = payload;
    },
    nickNameChange(state, payload) {
      localStorage.setItem("nickName", payload);
      state.nickName = payload;
    },
    imageUrlChange(state, payload) {
      localStorage.setItem("imageUrl", payload);
      state.imageUrl = payload;
    },
    czNumChange(state, payload) {
      localStorage.setItem("czNum", payload);
      state.czNum = payload;
    },
    djNumChange(state, payload) {
      localStorage.setItem("djNum", payload);
      state.djNum = payload;
    },
  },
  actions: {},
  modules: {},
});
