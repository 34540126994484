import Vue from 'vue'
import VueRouter from 'vue-router'
// 防止重复点击报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function (location) {
  return originalPush.call(this, location).catch(err => { })
};

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    redirect: "/homeView/home"
  },
  {
    path: '/homeView',
    name: 'homeView',
    // redirect: "/homeView/personCenter",
    component: () => import(/* webpackChunkName: "homeView" */ '@/views/home/HomeView.vue'),
    children: [
      {
        path: "/homeView/home",
        name: "home",
        component: () => import(/* webpackChunkName: "home" */ '@/views/home/Home.vue'),
      },
      {
        path: "/homeView/deposit",  // 版权存证
        name: "deposit",
        component: () => import(/* webpackChunkName: "deposit" */ '@/views/home/Deposit.vue'),
      },
      {
        path: "/homeView/register",  // 版权登记
        name: "register",
        component: () => import(/* webpackChunkName: "register" */ '@/views/home/Register.vue'),
      },
      {
        path: "/homeView/query",  // 证书查询
        name: "query",
        component: () => import(/* webpackChunkName: "query" */ '@/views/home/Query.vue'),
      },
      {
        path: "/homeView/customers",  // 服务客户
        name: "customers",
        component: () => import(/* webpackChunkName: "customers" */ '@/views/home/Customers.vue'),
      },
      {
        path: "/homeView/newsCenter",  // 新闻中心
        name: "newsCenter",
        component: () => import(/* webpackChunkName: "customers" */ '@/views/home/NewsCenter.vue'),
      },
      {
        path: "/homeView/tort",  // 侵权报案
        name: "tort",
        component: () => import(/* webpackChunkName: "tort" */ '@/views/home/Tort.vue'),
      },
      {
        path: "/homeView/personCenter",  // 个人中心
        name: "personCenter",
        component: () => import(/* webpackChunkName: "personCenter" */ '@/views/personCenter/PersonCenter.vue'),
        children: [
          {
            path: "/homeView/personCenter/authentication",  // 认证信息
            name: "authentication",
            component: () => import(/* webpackChunkName: "authentication" */ '@/views/personCenter/Authentication.vue'),
          },
          {
            path: "/homeView/personCenter/cenZhengWorks",  // 存证作品
            name: "cenZhengWorks",
            component: () => import(/* webpackChunkName: "cenZhengWorks" */ '@/views/personCenter/CenZhengWorks.vue'),
          },
          {
            path: "/homeView/personCenter/dengjiWorks",  // 登记作品
            name: "dengjiWorks",
            component: () => import(/* webpackChunkName: "dengjiWorks" */ '@/views/personCenter/DengjiWorks.vue'),
          },
          {
            path: "/homeView/personCenter/tortList",  // 侵权案件
            name: "tortList",
            component: () => import(/* webpackChunkName: "tortList" */ '@/views/personCenter/TortList.vue'),
          },
          {
            path: "/homeView/personCenter/personData",  // 个人信息
            name: "personData",
            component: () => import(/* webpackChunkName: "dengjiWorks" */ '@/views/personCenter/PersonData.vue'),
          },
        ]
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue')
  },
]

const router = new VueRouter({
  routes
})

export default router
